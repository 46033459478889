export const hiddenLookback = ['acceleration', 'lowvol', 'value']

export const strategiesNames = {
    "momentum": "Momentum",
    "acceleration": "Aceleração",
    "lowvol": "Low Vol",
    "mix_momentum": "Mix Momentum",
    "mix_acceleration": "Mix Aceleração",
    "mix_lowvol": "Mix Low Vol",
    "value": "Valor"
}

export const mixStrategiesNames = {
    "momentum": "Momentum",
    "acceleration": "Aceleração",
    "lowvol": "Low Vol",
    "mix_momentum": "Mix Momentum",
    "mix_acceleration": "Mix Aceleração",
    "mix_lowvol": "Mix Low Vol",
    "value": "Valor"
}