import axios from 'axios';
import { useSession } from 'contexts/session';
import { BASE_URL } from 'utils/apiUrl';

const useApi = () => {
    const { user } = useSession();
    const localToken = localStorage.getItem('@momentum:token')

    const api = axios.create({
        baseURL: BASE_URL,
        headers: user && { Authorization: `Bearer ${localToken ? localToken : user.token}` }
    })

    return { api };
}

export default useApi;