import React, { useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TextField,
    Grid,
    Typography,
    Box,
    Button
} from '@material-ui/core'
import { FilterList } from '@material-ui/icons';

import { useStrategy } from 'contexts/strategy';
import { FilterDialogMix } from 'components';
import { useMomentumTableStyles } from './styles';
import PriceChart from './PriceChart';
import { mixStrategiesNames } from 'utils/strategiesConfig';

export default function BasicTable() {
    const classes = useMomentumTableStyles();
    const { momentum, loading } = useStrategy();
    const [filter, setFilter] = useState(false);
    const [priceChart, setPriceChart] = useState(null)
    const [desiredInvestment, setDesiredInvestment] = useState(0)

    const closePriceChart = () => {
        setPriceChart(null)
    }

    if (!momentum || loading) return <h3>Carregando estratégias mix...</h3>

    return (
        <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            component={Paper}
            className={classes.root}
        >
            <Grid item>
                {
                    momentum &&
                    <Box
                        fullWidth
                        display="flex"
                        flexDirection={{ xs: 'column', lg: 'row' }}
                        alignItems={{ xs: 'flex-start', lg: "center" }}
                        style={{ gap: '1rem', padding: '1rem' }}
                    >
                        <Typography variant="h4">{mixStrategiesNames[momentum.type]} - </Typography>
                        <Typography variant="h4">{momentum.universe} - </Typography>
                        <Typography variant="h4">{momentum.date} - </Typography>
                        <Typography variant="h4">Lookback de {momentum.look_back} meses</Typography>
                    </Box>
                }
            </Grid>


            <Grid item style={{ padding: '1rem' }}>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<FilterList />}
                    onClick={() => setFilter(true)}>
                    Filtrar
                </Button>
            </Grid>

            <Grid item style={{ padding: '1rem' }}>
                <TextField
                    label="Valor da carteira"
                    variant="outlined"
                    value={desiredInvestment}
                    onChange={e => setDesiredInvestment(e.target.value)}
                />
            </Grid>

            <Grid item xs={12} style={{ width: '100%', padding: '1rem' }}>
                <TableContainer className={classes.container}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.tableCell}>Ação</TableCell>
                                <TableCell className={classes.tableCell}>Nome</TableCell>
                                <TableCell className={classes.tableCell}>Sinal</TableCell>
                                <TableCell className={classes.tableCell}>Risk Parity</TableCell>
                                <TableCell className={classes.tableCell}>Valor</TableCell>
                                <TableCell className={classes.tableCell}>Nº de Ações na Carteira</TableCell>
                                <TableCell className={classes.tableCell}>Setor</TableCell>
                                <TableCell className={classes.tableCell}>Peso Equally Weighted</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {momentum.response.map((row) => (
                                <TableRow key={row.stock}>
                                    <TableCell component="th" scope="row">
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            onClick={() => setPriceChart(row.stock)}
                                            style={{ width: '100%' }}
                                        >
                                            {row.stock}
                                        </Button>
                                    </TableCell>
                                    <TableCell>{row.name}</TableCell>
                                    <TableCell>{isNaN(parseFloat(row.signal).toFixed(2)) ? '' : parseFloat(row.signal).toFixed(2)}</TableCell>
                                    <TableCell>{parseFloat(row.risk_parity * 100).toFixed(2)}%</TableCell>
                                    <TableCell>R$ {row.last_price}</TableCell>
                                    <TableCell>{parseInt(desiredInvestment * row.risk_parity / row.last_price)}</TableCell>
                                    <TableCell>{row.setor}</TableCell>
                                    <TableCell>{parseFloat(row.eq_w * 100).toFixed(2)}%</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>


            <Grid item xs={12} style={{ width: '100%', padding: '1rem' }}>
                <Typography variant="caption">
                    * valores coletados no fechamento do dia anterior
                </Typography>
            </Grid>

            <FilterDialogMix
                visible={filter}
                handleClose={() => setFilter(false)}
            />
            <PriceChart
                price={priceChart}
                visible={priceChart}
                handleClose={closePriceChart}
            />
        </Grid>
    );
}