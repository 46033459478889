import React, { useState, cloneElement } from 'react';

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle
} from '@material-ui/core';

export default function AlertDialog(props) {
    const [open, setOpen] = useState(false);
    const {
        trigger,
        title,
        children,
        buttonLabel = 'Fechar'
    } = props;

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            {cloneElement(trigger, { onClick: handleClickOpen })}
            <Dialog
                fullWidth
                maxWidth="md"
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    {children}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" autoFocus>
                        {buttonLabel}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}