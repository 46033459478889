import { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import {
  AppBar,
  Avatar,
  Box,
  CssBaseline,
  Divider,
  Drawer,
  FormHelperText,
  Grid,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  Typography,
  Toolbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import TimelineOutlinedIcon from '@material-ui/icons/TimelineOutlined';
import MultilineChartIcon from '@material-ui/icons/MultilineChart';
import MenuIcon from '@material-ui/icons/Menu';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import DescriptionIcon from '@material-ui/icons/Description';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import PersonIcon from '@material-ui/icons/Person';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import AccountBalanceWallet from '@material-ui/icons/AccountBalanceWallet';
import { generateAvatar } from 'utils/helper';
import { useSession } from 'contexts/session';
import { useTheme } from '@material-ui/core/styles';
import { useDrawerStyles } from './styles';
import easyquant from 'assets/easyquant.svg';

function ResponsiveDrawer(props) {
  const { window, children } = props;
  const { user, logout } = useSession();
  const classes = useDrawerStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isDialogDownloadWalletOpen, setIsDialogDownloadWalletOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleOpenDialogWallet = () => {
    setIsDialogDownloadWalletOpen(true);
  }

  const handleCloseDialogWallet = () => {
    setIsDialogDownloadWalletOpen(false);
  }

  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <List>
        <ListItem style={{ gap: '1rem' }}>
          <Avatar>{generateAvatar(user.name)}</Avatar>
          <Box>
            <Typography>{user.name}</Typography>
            {
              (user && !user.active) &&
              <Typography variant="caption">conta inativa</Typography>
            }
          </Box>
        </ListItem>
      </List>
      <Divider />
      <Fragment>
        <List>
          {
            (user && user.active) &&
            <>
              <ListItem component={RouterLink} to='/blog' color="primary" button>
                <ListItemIcon><DescriptionIcon /></ListItemIcon>
                <Typography>Blog</Typography>
              </ListItem>
              <ListItem component={RouterLink} to='/estrategias' color="primary" button>
                <ListItemIcon><TimelineOutlinedIcon /></ListItemIcon>
                <Typography>Estratégias</Typography>
              </ListItem>
              <ListItem component={RouterLink} to='/estrategias-mix' color="primary" button>
                <ListItemIcon><MultilineChartIcon /></ListItemIcon>
                <Typography>Estratégias Mix</Typography>
              </ListItem>
            </>
          }
          <ListItem component={RouterLink} to='/utilidades' color="primary" button>
            <ListItemIcon><BusinessCenterIcon /></ListItemIcon>
            <Typography>Utilidades</Typography>
          </ListItem>
          <ListItem component={RouterLink} color="primary" button onClick={handleOpenDialogWallet} >
            <ListItemIcon><AccountBalanceWallet /></ListItemIcon>
            <Typography>Gerenciador de Carteira</Typography>
          </ListItem>
        </List>
        <Divider />
      </Fragment>
      <List>
        <ListItem component={RouterLink} to='/perfil' color="primary" button>
          <ListItemIcon><PersonIcon /></ListItemIcon>
          <Typography>Perfil</Typography>
        </ListItem>
        <ListItem component={RouterLink} to='/manual' color="primary" button>
          <ListItemIcon><MenuBookIcon /></ListItemIcon>
          <Typography>Manual</Typography>
        </ListItem>
        <ListItem component={RouterLink} to='/' color="primary" onClick={logout} button>
          <ListItemIcon><ExitToAppIcon /></ListItemIcon>
          <Typography>Sair</Typography>
        </ListItem>
      </List>
      <Divider />
      <List>
        <Box pt={2}>
          <Grid container item direction="column" justifyContent="flex-end" alignItems="center">
            <FormHelperText>contato@easyquant.com.br</FormHelperText>
          </Grid>
        </Box>
      </List>
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap style={{ fontFamily: 'Vudotronic' }}>
            <div style={{ width: 120, height: 32 }}>
              <img src={easyquant} style={{ width: '100%', objectFit: 'contain' }} alt="easyquant logotipo" />
            </div>
          </Typography>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>

        <Dialog
          open={isDialogDownloadWalletOpen}
          fullWidth
          maxWidth="md"
          onClose={handleCloseDialogWallet}
          aria-labelledby="Download da carteira"
          aria-describedby="Deseja realizar o download da carteira?"
        >
          <DialogTitle id="wallet-download">{"Deseja acessar o gerenciador de carteira?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="dialog-description">
              Você será redirecionado para uma nova página.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialogWallet} color="primary">
              Cancelar
            </Button>
            <Button
              onClick={handleCloseDialogWallet}
              color="primary"
              autoFocus
              href="https://docs.google.com/spreadsheets/d/1ZtJ6TpLmxSHB_m_G52wENszk9dKR8maeNFOsrz2Xb0w/edit#gid=0"
              target='_blank'
            >
              Confirmar
            </Button>
          </DialogActions>
        </Dialog>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </main>
    </div>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default ResponsiveDrawer;