import { useState, useEffect } from 'react';
import {
    Box,
    DialogTitle,
    Dialog,
    IconButton,
    DialogContent,
    Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Line } from '@reactchartjs/react-chart.js';
import { useDeviceDetect, useApi } from 'utils/hooks';

export default function PriceChart(props) {
    const { visible, handleClose, price } = props;
    const { isMobile } = useDeviceDetect();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([])
    const [options, setOptions] = useState(null)
    const { api } = useApi();

    useEffect(() => {
        if (!price) return
        setLoading(true)
        api.post('/ticker/search', {
            ticker: price,
            type: "prices",
            source: "alpha"
        })
            .then(res => {
                var prices = []

                try {
                    prices = res.data.prices[0].response
                } catch (e) {
                    console.log(e)
                }

                let newData = {
                    labels: [],
                    datasets: [
                        {
                            label: 'Preço',
                            data: [],
                            fill: false,
                            baackgroundColor: 'rgba(0, 200, 0, 0.5)',
                            borderColor: 'rgba(0, 200, 0, 0.5)',
                            tick: {
                                callback: (value) => `R$ ${parseFloat(value).toFixed(2)}`
                            }
                        }
                    ],
                }

                prices.forEach(p => {
                    if (p[price] !== null) {
                        newData.datasets[0].data.push(p[price])
                        newData.labels.push(`${p.index.split('-')[0]}/${p.index.split('-')[1]}`)
                    }
                })

                setData(newData)
                setOptions({
                    tooltips: {
                        callbacks: {
                            label: ({ value }) => `R$ ${parseFloat(value).toFixed(2)}`,
                        }
                    },
                    elements: {
                        point: {
                            radius: 0
                        }
                    },
                    scales: {
                        yAxes: [
                            {
                                type: 'linear',
                                display: true,
                                position: 'left',
                                id: 'y-axis-1',
                                ticks: {
                                    callback: (value) => `R$ ${parseFloat(value).toFixed(2)}`
                                }
                            }
                        ],
                        xAxes: [
                            {
                                gridLines: {
                                    display: false
                                }
                            }
                        ]
                    },
                })
                setLoading(false)
            })
            .catch(err => {
                console.log(err)
                setLoading(false)
            })
        // eslint-disable-next-line
    }, [price])

    return (
        <Dialog
            fullWidth
            maxWidth="xl"
            open={visible}>
            <DialogTitle>
                <Box style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography variant="h4">{price}</Typography>
                    <IconButton
                        onClick={handleClose}
                        aria-label="close">
                        <CloseIcon />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                <Typography variant="h4">{price}</Typography>
                {
                    loading ?
                        <Typography variant="h5">Gerando tabela e gráfico</Typography> :
                        <Line data={data} options={options} height={isMobile ? 200 : 100} />
                }
            </DialogContent>
        </Dialog>
    );
}