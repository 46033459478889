import { Redirect } from 'react-router-dom';
import { useSession } from 'contexts/session';
import PropTypes from 'prop-types';

function ProtectedRoute({ component: Component, render, ...rest }) {
    const { user } = useSession();

    if (!user.active) {
        return <Redirect to='/perfil' />;
    }

    return render ? render({ ...rest }) : <Component {...rest} />;
}

ProtectedRoute.propTypes = {
    component: PropTypes.any,
    render: PropTypes.func,
};

export default ProtectedRoute;