import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';

import faq from 'utils/faq.json';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: 'bold'
    },
}));

export default function Faq() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            {
                faq.map((item, index) =>
                    <Accordion key={index}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography className={classes.heading}>{item.theme.name}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div>
                                {
                                    item.theme.questions.map((subitem, subindex) =>
                                        <Accordion key={subindex}>
                                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                <Typography className={classes.heading}>{subitem.question}</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography>{subitem.answer}</Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                    )
                                }
                            </div>
                        </AccordionDetails>
                    </Accordion>
                )
            }
        </div>
    );
}