import { useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    TextField,
    FormHelperText,
    CircularProgress,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';

import { useSession } from 'contexts/session';
import { easyquant } from "api";
import MaskedInput from './MaskedInput';

function CardFormDialog() {
    const { user } = useSession();
    const { enqueueSnackbar } = useSnackbar();
    const [values, setValues] = useState(initialValues)
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
    };

    const handleInputFocus = (e) => {
        setValues({ ...values, focus: e.target.name });
    }

    const clearInputFocus = () => {
        setValues({ ...values, focus: '' });
    }

    const handleSubmit = () => {
        setLoading(true)
        easyquant.post('/user/edit_credit_card', {
            ...user,
            ...values
        })
            .then(() => {
                setError(false)
                setLoading(false)
                enqueueSnackbar('Cartão alterado com sucesso', { variant: "success" })
                handleClose()
            })
            .catch((err) => {
                setError(false)
                setLoading(false)
                setError(`Falha ao alterar cartão: ${err}`)
            })
    }


    return (
        <>
            <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                Alterar Cartão de Crédito
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Editar Cartão de Crédito"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Informe os novos dados do seu cartão de crédito.
                    </DialogContentText>
                    <form>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <MaskedInput
                                    fullWidth
                                    value={values.card_number}
                                    onChange={handleChange}
                                    name="card_number"
                                    label="Número"
                                    variant="outlined"
                                    type="text"
                                    pattern="[0-9]*"
                                    onFocus={handleInputFocus}
                                    mask="9999 9999 9999 9999"
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    value={values.card_name}
                                    onChange={handleChange}
                                    name="card_name"
                                    label="Nome no cartão"
                                    variant="outlined"
                                    type="text"
                                    onFocus={handleInputFocus}
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <MaskedInput
                                    fullWidth
                                    value={values.card_expiry}
                                    onChange={handleChange}
                                    name="card_expiry"
                                    label="Vencimento"
                                    variant="outlined"
                                    type="text"
                                    onFocus={handleInputFocus}
                                    mask="99/9999"
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    value={values.cvc}
                                    onChange={handleChange}
                                    name="cvc"
                                    label="CVC"
                                    variant="outlined"
                                    type="numeric"
                                    onFocus={handleInputFocus}
                                    onBlur={clearInputFocus}
                                />
                            </Grid>
                        </Grid>
                        <FormHelperText error>{error}</FormHelperText>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancelar
                    </Button>
                    <Button
                        onClick={handleSubmit}
                        color="primary"
                        autoFocus
                        endIcon={loading && <CircularProgress color="white" size={24} />}
                        disabled={loading}
                    >
                        Enviar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

const initialValues = {
    name: '',
    focus: '',
    card_cvc: '',
    card_expiry: '',
    card_name: '',
    card_number: '',
}

export default CardFormDialog;