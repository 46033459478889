import { useEffect, useState } from 'react';
import { TextField, FormHelperText } from '@material-ui/core';

function Recaptcha(props) {
    const { onValidateTrue, onValidateFalse } = props;
    const [value, setValue] = useState(null);
    const [isCorrect, setIsCorrect] = useState(false);
    const [challenge, setChallenge] = useState({
        a: '',
        b: '',
        op: '',
        answer: ''
    })

    function randNum(min, max) {
        return Math.floor(Math.random() * (max - min + 1) + min)
    }

    useEffect(() => {
        const a = randNum(1, 10)
        const b = randNum(1, 10)
        const op = randNum(0, 2)
        let answer;

        switch (op) {
            case 0:
                answer = a + b;
                break;
            case 1:
                answer = a - b;
                break;
            case 2:
                answer = a * b;
                break;
            default:
                answer = a + b;
                break;
        }

        setChallenge({
            a: a,
            b: b,
            op: op,
            answer: answer
        })
    }, [])

    useEffect(() => {
        if (`${value}` === `${challenge.answer}`) {
            onValidateTrue();
            setIsCorrect(true)
        } else {
            onValidateFalse();
            setIsCorrect(false)
        }
        // eslint-disable-next-line
    }, [value])

    function handleChange(e) {
        setValue(e.target.value);
    }

    return (
        <>
            <TextField
                fullWidth
                value={value}
                onChange={handleChange}
                name="recaptcha"
                label={`Responda: ${challenge.a} ${op[challenge.op]} ${challenge.b} = ?`}
                variant="outlined"
                type="numeric"
            />
            <FormHelperText error={!isCorrect && value}>
                {
                    value ?
                        isCorrect ?
                            'Correto' :
                            'Incorreto' :
                        ''
                }
            </FormHelperText>
        </>
    )
}

const op = {
    "0": "+",
    "1": "-",
    "2": "*",
}

export default Recaptcha;