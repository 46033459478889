import { useState, useEffect } from 'react'
import {
    Button,
    Paper,
    Typography,
    FormControl,
    InputLabel,
    MenuItem,
    Select
} from '@material-ui/core'
import { Line } from '@reactchartjs/react-chart.js'
import TimelineIcon from '@material-ui/icons/Timeline';

import { AlertDialog } from 'components';
import { useStrategy } from 'contexts/strategy';
import { useDeviceDetect } from 'utils/hooks';

export default function MultiAxisLine() {
    const { backtest, filterBacktest, loading } = useStrategy();
    const { isMobile } = useDeviceDetect();
    const [data, setData] = useState(null);
    const [options, setOptions] = useState(null)
    const [bt_interval, setBtInterval] = useState(10)

    useEffect(() => {

        const universe_name = {
            IBOV: 'Ibovespa',
            IBRA: 'IBrA',
            IBXX: 'IBrX100'
        }

        let newData = {
            labels: [],
            datasets: [
                {
                    label: 'Equally Weighted',
                    data: [],
                    fill: false,
                    backgroundColor: 'rgb(200, 0, 0)',
                    borderColor: 'rgba(200, 0, 0, 0.5)',
                    pointRadius: 0
                },
                {
                    label: universe_name[backtest?.universe],
                    data: [],
                    fill: false,
                    backgroundColor: 'rgb(0, 200, 0)',
                    borderColor: 'rgba(0, 200, 0, 0.5)',
                    pointRadius: 0
                },
                {
                    label: 'Risk Parity',
                    data: [],
                    fill: false,
                    backgroundColor: 'rgb(0, 0, 200)',
                    borderColor: 'rgba(0, 0, 200, 0.5)',
                    pointRadius: 0
                },
            ],
        }

        let yAxis = {}

        if (backtest) {
            let min = backtest.dd[0].equally_weighted
            let max = backtest.dd[0].equally_weighted

            backtest.dd.forEach((i) => {
                if (i.equally_weighted < min) {
                    min = i.equally_weighted
                }

                if (i.benchmark < min) {
                    min = i.benchmark
                }

                if (i.risk_parity < min) {
                    min = i.risk_parity
                }

                if (i.equally_weighted > max) {
                    max = i.equally_weighted
                }

                if (i.benchmark > max) {
                    max = i.benchmark
                }

                if (i.risk_parity > max) {
                    max = i.risk_parity
                }

                newData.labels.push(`${i.Date.split('-')[0]}/${i.Date.split('-')[1]}`)
                newData.datasets[0].data.push(i.equally_weighted)
                newData.datasets[1].data.push(i.benchmark)
                newData.datasets[2].data.push(i.risk_parity)
            })

            yAxis = {
                min: Math.trunc(min) - 1,
                max: Math.trunc(max) + 1,
            }
        }

        setOptions({
            scales: {
                yAxes: [
                    {
                        type: 'linear',
                        display: true,
                        position: 'left',
                        id: 'y-axis-1',
                        min: yAxis.min,
                        max: yAxis.max
                    },
                    {
                        type: 'linear',
                        display: false,
                        position: 'right',
                        id: 'y-axis-2',
                        min: yAxis.min,
                        max: yAxis.max
                    },
                    {
                        type: 'linear',
                        display: false,
                        position: 'left',
                        id: 'y-axis-3',
                        min: yAxis.min,
                        max: yAxis.max
                    },
                ],

                xAxes: [
                    {
                        gridLines: {
                            display: false,
                        }
                    }
                ]
            },
        })
        setData(newData)
    }, [backtest])

    const formattedInterval = () => {
        if (backtest) {
            return backtest.bt_interval > 1 ? `${backtest.bt_interval} anos` : '1 ano'
        }

        return ''
    }


    if (loading || !data) return (
        <Paper style={{ width: '100%', padding: 10 }} height={isMobile ? 300 : 800} >
            <Typography variant="h4">Gerando gráfico...</Typography>
        </Paper>

    )

    return (
        <Paper style={{ width: '100%', padding: 10 }} height={isMobile ? 300 : 800}>
            <Typography variant="h4">Drawdown - {formattedInterval()}</Typography>
            <AlertDialog
                title="Selecione o intervalo do drawdown"
                trigger={
                    <Button startIcon={<TimelineIcon />} variant="contained" color="primary" style={{ margin: '1rem 0' }}>
                        intervalo
                    </Button>
                }
                confirmAction={() => filterBacktest(bt_interval)}
            >
                <FormControl fullWidth>
                    <InputLabel id="bt_interval">Intervalo do drawdown</InputLabel>
                    <Select
                        labelId="bt_interval"
                        id="bt_interval"
                        value={bt_interval}
                        onChange={(e) => setBtInterval(e.target.value)}
                    >
                        <MenuItem value={10}>10 anos</MenuItem>
                        <MenuItem value={5}>5 anos</MenuItem>
                        <MenuItem value={3}>3 anos</MenuItem>
                        <MenuItem value={1}>1 ano</MenuItem>
                    </Select>
                </FormControl>
            </AlertDialog>
            <Line data={data} options={options} height={isMobile ? 200 : 100} />
        </Paper>
    )
}