import { makeStyles } from '@material-ui/core/styles';

export const useMomentumTableStyles = makeStyles({
  root: {
    width: '88vw',
    "@media (min-width: 600px)": {
      width: '100%',
    },
    overflowX: 'auto'
  },
  container: {
    width: '100%'
  },
  tableRow: {
    '& :hover': {
      cursor: 'pointer',
      backgroundColor: 'rgba(0,0,0,.1)'
    }
  },
  tableCell:{
    maxWidth: 100
  }
});