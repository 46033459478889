
import { useState } from 'react';
import { IconButton, Collapse } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';

export default function NewNotification(props) {
    const { children } = props;
    const [open, setOpen] = useState(true);

    return (
        <Collapse
            in={open}
            style={{
                marginBottom: 10
            }}
        >
            <Alert
                action={
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                            setOpen(false);
                        }}
                    >
                        <CloseIcon fontSize="inherit" />
                    </IconButton>
                }
            >
                {children}
            </Alert>
        </Collapse>
    );
}