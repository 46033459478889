import axios from "axios";

const BASE_URL = process.env.REACT_APP_EASYQUANT_API;
const TOKEN = JSON.parse(window.localStorage.getItem("@momentum:user"))?.token;

const instance = axios.create({
    baseURL: BASE_URL,
    headers: {
        Authorization: `Bearer ${TOKEN}`,
    },
});

export default instance;